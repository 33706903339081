import React, {useEffect, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, CircularProgress, DialogTitle,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Formik} from 'formik';
import TextField from 'components/Form/TextField';
import AutoCompleteField from 'components/Form/AutoCompleteField';
import Yup from 'services/yup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'

const fieldsValidations = {
  supplierId: Yup.string().required('זהו שדה חובה'),
  currency: Yup.string().required('זהו שדה חובה'),
  paymentTerms: Yup.string().required('זהו שדה חובה'),
  comments: Yup.string(),
};

const validationSchema = Yup.object().shape(fieldsValidations);
const fieldKeys = _.keys(fieldsValidations);
const emptySupplier = _.transform(fieldKeys, (aggr, key) => aggr[key] = '', {});

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  }
}));

export default function CreateEditProjectSupplier({open, onClose, onSubmit, loading, currencies, suppliers, justSubmitted, supplier, submitError}) {
  supplier = supplier || _.clone(emptySupplier)
  const currenciesValues = currencies.map(c => ({value: c.code, label: `${c.name} (${c.symbol})`}))
  const suppliersValues = suppliers.map(c => ({value: c.id, label: c.name }))

  const [supplierVal, setProjectVal] = useState(_.clone(supplier || emptySupplier));
  useEffect(() => {
    if (open && justSubmitted) {
      onClose();
    }
  }, [justSubmitted, onClose, open])
  
  useEffect(() => {
    if (!_.isEqual(supplierVal, supplier)) {
      setProjectVal(_.clone(supplier || emptySupplier))
    }
  }, [supplier, supplierVal])

  const classes = useStyles();
  const texts = {
    title: supplierVal.id ? `עריכת ספק - ${supplier.name}` : 'שיוך ספק חדש לפרויקט',
    subtitle: supplierVal.id ? 'עריכת פרטי הספק:' : 'כדי לשייך ספק לפרויקט יש למלא את הפרטים הבאים:',
    action: supplierVal.id ? 'עריכה' : 'שיוך',
  }
  return (
  <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="submit-project-title" disableBackdropClick disableEscapeKeyDown={loading}>
    <DialogTitle classes={{root: classes.dialogTitle}} id="submit-project-title">{texts.title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{texts.subtitle}</DialogContentText>
      {open && (<Formik
        initialValues={supplierVal}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <AutoCompleteField
                name="supplierId"
                label="ספק"
                options={suppliersValues}
                fieldProps={{
                  autoFocus: true,
                  InputProps: {readOnly: loading},
                }}
              />
              <AutoCompleteField
                name="currency"
                label="מטבע"
                options={currenciesValues}
                fieldProps={{
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="paymentTerms"
                fieldProps={{
                  label: 'תנאי תשלום',
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="comments"
                fieldProps={{
                  label: 'הערות',
                  InputProps: {readOnly: loading},
                }}
              />
              {submitError && <Alert severity="error">{submitError}</Alert>}
              <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                  ביטול
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {texts.action}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
      )}
    </DialogContent>
  </Dialog>
  );
}