import {connect} from 'utils/reduxUtils';
import Component from './ProjectSupplierSummary';
import * as SettingsSelectors from 'modules/settings/selectors';
import * as ProjectsSelectors from 'modules/projects/selectors';
import * as PSSelectors from 'modules/projectSuppliers/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onRefreshRates: Actions.PS_REFRESH_RATES_CLICKED,
};

const mapStateToProps = state => {
    return {
        paymentsSummary: PSSelectors.paymentsSummary(state),
        activitiesSummary: PSSelectors.activitiesSummary(state),
        currenciesLastUpdated: SettingsSelectors.currenciesLastUpdated(state),
        updatingCurrencies: SettingsSelectors.updatingCurrencies(state),
        settings: SettingsSelectors.settings(state),
        rate: ProjectsSelectors.selectedSupplierCurrencyRate(state),
        showRateConversion: ProjectsSelectors.selectedSupplierCurrency(state) !== 'ILS',
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
