import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_SUPPLIER_PAYMENTS_REQUEST',
    'FETCH_SUPPLIER_PAYMENTS_SUCCESS',
    'FETCH_SUPPLIER_PAYMENTS_FAILURE',
    
    'PAYMENT_FORM_SUBMIT_REQUEST',
    'PAYMENT_FORM_SUBMIT_SUCCESS',
    'PAYMENT_FORM_SUBMIT_FAILURE',
    
    'FETCH_ACTIVITIES_REQUEST',
    'FETCH_ACTIVITIES_SUCCESS',
    'FETCH_ACTIVITIES_FAILURE',
    
    'ACTIVITY_FORM_SUBMIT_REQUEST',
    'ACTIVITY_FORM_SUBMIT_SUCCESS',
    'ACTIVITY_FORM_SUBMIT_FAILURE',
    
    'ACTIVITY_BULK_FORM_SUBMIT_REQUEST',
    'ACTIVITY_BULK_FORM_SUBMIT_SUCCESS',
    'ACTIVITY_BULK_FORM_SUBMIT_FAILURE',
    
    'ACTIVITY_BULK_ADD_FORM_SUBMIT_REQUEST',
    'ACTIVITY_BULK_ADD_FORM_SUBMIT_SUCCESS',
    'ACTIVITY_BULK_ADD_FORM_SUBMIT_FAILURE',
    
    'PROJECT_SUP_GET_REPORT_REQUEST',
    'PROJECT_SUP_GET_REPORT_SUCCESS',
    'PROJECT_SUP_GET_REPORT_FAILURE',
    
    'ACTIVITY_CHANGE_STATE_REQUEST',
    'ACTIVITY_CHANGE_STATE_SUCCESS',
    'ACTIVITY_CHANGE_STATE_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
