import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'LOAD_SETTINGS_REQUEST',
    'LOAD_SETTINGS_SUCCESS',
    'LOAD_SETTINGS_FAILURE',

    'UPDATE_CURRENCIES_REQUEST',
    'UPDATE_CURRENCIES_SUCCESS',
    'UPDATE_CURRENCIES_FAILURE',

    'UPDATE_SETTINGS_REQUEST',
    'UPDATE_SETTINGS_SUCCESS',
    'UPDATE_SETTINGS_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
