import {ActionTypes} from './actions';
import {ActionTypes as PagesActionTypes} from 'pages/actions';
import _ from 'lodash';
import { mergeBy } from 'utils/reduxUtils';

const initialValue = {
  filter: {showArchived: false},
  singleFilter: {showArchived: false},
}

const formatError = (payload) => {
  const errorCode = _.get(payload, 'response.data.key');
  switch (errorCode) {
    case 'matchingActiveSupplierExists':
      return 'פרויקט זה כבר מכיל את הספק + המטבע הללו';
    default:
      return 'אופס. נראה שהבקשה נכשלה';
  }
}
const projectsReducer = (state = initialValue, action) => {
  let nextState;
  const {type, payload} = action;
  switch (type) {
  case PagesActionTypes.PROJECTS_PAGE_SHOW_ARCHIVED_CHANGE:
    nextState = {...state, filter: {showArchived: payload}};
    break;
  case ActionTypes.PROJECT_CHANGE_STATE_SUCCESS:
    nextState = {...state, projects: state.projects.filter(p => p.id !== payload.id)};
    break;
  case ActionTypes.PROJECT_SUPPLIER_CHANGE_STATE_SUCCESS: {
    const {suppliers} = state.selectedProject;
    const selectedProject = {...state.selectedProject, suppliers: suppliers.filter(p => p.id !== payload.id)}
    nextState = {...state, selectedProject};
    break;
  }
  case PagesActionTypes.S_PROJECT_PAGE_ARCHIVED_TOGGLED:
    nextState = {...state, singleFilter: {showArchived: payload}};
    break;
  case ActionTypes.FETCH_PROJECTS_REQUEST:
    nextState = {...state, projects: [], loading: true};
    break;
  case ActionTypes.PROJECT_FORM_SUBMIT_REQUEST:
  case ActionTypes.EDIT_PROJECT_SUPPLIER_REQUEST:
    nextState = {...state, submitting: true, justSubmitted: false, submitError: null};
    break;
  case ActionTypes.PROJECT_FORM_SUBMIT_SUCCESS:
    nextState = {...state, submitting: false, justSubmitted: true, projects: mergeBy(state.projects, payload)};
    break;
  case ActionTypes.EDIT_PROJECT_SUPPLIER_SUCCESS: {
    const suppliers = mergeBy(state.selectedProject.suppliers, payload);
    nextState = {...state, submitting: false, justSubmitted: true, selectedProject: {...state.selectedProject, suppliers}};
    break;
  }
  case ActionTypes.PROJECT_FORM_SUBMIT_FAILURE:
  case ActionTypes.EDIT_PROJECT_SUPPLIER_FAILURE:
    nextState = {...state, submitting: false, submitError: formatError(payload)};
    break;
    
  case ActionTypes.FETCH_PROJECTS_SUCCESS:
    nextState = {...state, projects: payload.items, loading: false};
    break;
  case ActionTypes.FETCH_S_PROJECT_REQUEST:
  case ActionTypes.FETCH_S_ACTIVITIES_REQUEST:
    nextState = {...state, selectedProject: null, loading: true};
    break;
  case ActionTypes.FETCH_S_PROJECT_SUCCESS:
  case ActionTypes.FETCH_S_ACTIVITIES_SUCCESS:
    nextState = {...state, selectedProject: payload, loading: false};
    break;
  case ActionTypes.ADD_PROJECT_FAILURE:
  case ActionTypes.FETCH_PROJECTS_FAILURE:
  case ActionTypes.FETCH_S_PROJECT_FAILURE:
    nextState = {...state, loading: false};
    break;
  default:
    nextState = state;
  }
  return nextState;
};

export default projectsReducer;
