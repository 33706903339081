import {put, takeLatest, call, select, all} from 'redux-saga/effects';
import * as Selectors from 'modules/projects/selectors';
import {Actions} from 'modules/projects/actions';
import axios from 'services/axios';
import {ActionTypes as PagesActions} from 'pages/actions';
import fileSaver from 'file-saver';
import _ from 'lodash';
import { s2ab } from 'utils/transformers';

function* fetchProjects() {
    yield put(Actions.FETCH_PROJECTS_REQUEST());
    try {
        const {showArchived} = yield select(Selectors.filter);
        const query = `active=${!showArchived}`;
        const {data} = yield call(axios.get, `projects?${query}`);
        
        yield put(Actions.FETCH_PROJECTS_SUCCESS(data));
    } catch (err) {
        console.error(err);
        yield put(Actions.FETCH_PROJECTS_FAILURE(err));
    }
}

function* fetchProjectWithSuppliers(action) {
    yield put(Actions.FETCH_S_PROJECT_REQUEST());
    try {
        const {showArchived} = yield select(Selectors.singleFilter);
        const query = `active=${!showArchived}`;
        const projectId = yield select(Selectors.matchingProjectId);
        const {project, suppliers} = yield all({
            project: call(axios.get, `projects/${projectId}`),
            suppliers: call(axios.get, `projects/${projectId}/suppliers?${query}`),
        })

        yield put(Actions.FETCH_S_PROJECT_SUCCESS({...project.data, ...suppliers.data}));
    } catch (err) {
        yield put(Actions.FETCH_S_PROJECT_FAILURE(err));
    }
}

function* fetchProjectWithActivities(action) {
    yield put(Actions.FETCH_S_ACTIVITIES_REQUEST());
    try {
        const projectId = yield select(Selectors.matchingProjectId);
        const supplierId = yield select(Selectors.matchingSupplierId);
        const {project, supplier} = yield all({
            project: call(axios.get, `projects/${projectId}`),
            supplier: call(axios.get, `projects/${projectId}/suppliers/${supplierId}`),
        })

        yield put(Actions.FETCH_S_ACTIVITIES_SUCCESS({...project.data, supplier: supplier.data}));
    } catch (err) {
        yield put(Actions.FETCH_S_ACTIVITIES_FAILURE(err));
    }
}

function* submitProjectForm(action) {
    yield put(Actions.PROJECT_FORM_SUBMIT_REQUEST());
    try {
        const {payload} = action;
        const projectId = payload.id;
        const fields = _.pick(payload, ['name', 'phone', 'email', 'location', 'details']);
        const method = projectId ? axios.patch : axios.post;
        const url = projectId ? `projects/${projectId}` : `projects`;
        const result = yield call(method, url, fields);

        yield put(Actions.PROJECT_FORM_SUBMIT_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.PROJECT_FORM_SUBMIT_FAILURE(err));
    }
}

function* changeProjectState(action) {
    yield put(Actions.PROJECT_CHANGE_STATE_REQUEST());
    try {
        const {payload} = action;
        const result = yield call(axios.patch, `projects/${payload.id}`, {active: !payload.active});

        yield put(Actions.PROJECT_CHANGE_STATE_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.PROJECT_CHANGE_STATE_FAILURE(err));
    }
}

function* submitProjectSupplierForm(action) {
    yield put(Actions.EDIT_PROJECT_SUPPLIER_REQUEST());
    try {
        const {payload} = action;
        const projSupplierId = payload.id || '';
        const projectId = yield select(Selectors.matchingProjectId);
        const fields = _.pick(payload, ['supplierId', 'currency', 'active', 'paymentTerms', 'comments']);
        const method = projSupplierId ? axios.patch : axios.post;
        const url = `projects/${projectId}/suppliers/${projSupplierId}`;
        const result = yield call(method, url, fields);

        yield put(Actions.EDIT_PROJECT_SUPPLIER_SUCCESS(result.data));
    } catch (err) {
        console.error(err);
        yield put(Actions.EDIT_PROJECT_SUPPLIER_FAILURE(err));
    }
}

function* changeProjectSupplierState(action) {
    yield put(Actions.PROJECT_SUPPLIER_CHANGE_STATE_REQUEST());
    try {
        const {payload} = action;
        const projectId = yield select(Selectors.matchingProjectId);
        const result = yield call(axios.patch, `projects/${projectId}/suppliers/${payload.id}`, {active: !payload.active});

        yield put(Actions.PROJECT_SUPPLIER_CHANGE_STATE_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.PROJECT_SUPPLIER_CHANGE_STATE_FAILURE(err));
    }
}

function* downloadProjectReport() {
    try {
        const project = yield select(Selectors.selectedProject);
        
        yield put(Actions.PROJECT_GET_REPORT_REQUEST());
        const result = yield call(axios.post, `projects/${project.id}/prepareReport`);
        const currentDate = new Date().toJSON().slice(0,10).split('-').reverse().join('.');
        fileSaver.saveAs(new Blob([s2ab(atob(result.data))], {type: result.headers['content-type']}), `${project.name}-מסך פרויקט-${currentDate}.xlsx`);
        yield put(Actions.PROJECT_GET_REPORT_SUCCESS());
    } catch (err) {
        yield put(Actions.PROJECT_GET_REPORT_FAILURE(err));
    }
}

const sagas = [
    takeLatest([PagesActions.PROJECTS_PAGE_MOUNTED, PagesActions.PROJECTS_PAGE_SHOW_ARCHIVED_CHANGE], fetchProjects),
    takeLatest([PagesActions.S_PROJECT_PAGE_MOUNTED, PagesActions.S_PROJECT_PAGE_ARCHIVED_TOGGLED], fetchProjectWithSuppliers),
    takeLatest([PagesActions.PROJECT_SUP_PAGE_MOUNTED], fetchProjectWithActivities),
    takeLatest(PagesActions.PROJECT_FORM_SUBMITTED, submitProjectForm),
    takeLatest(PagesActions.PROJECT_SUPPLIER_FORM_SUBMITTED, submitProjectSupplierForm),
    takeLatest(PagesActions.PROJECTS_PAGE_TOGGLE_PROJECT_STATE, changeProjectState),
    takeLatest(PagesActions.S_PROJECT_PAGE_TOGGLE_SUPPLIER_STATE, changeProjectSupplierState),
    takeLatest(PagesActions.S_PROJECT_PAGE_DOWNLOAD_REPORT, downloadProjectReport),
]
export default sagas;
