import {connect} from 'utils/reduxUtils';
import Component from './PSPayments';
import * as SettingsSelectors from 'modules/settings/selectors';
import * as ProjectsSelectors from 'modules/projects/selectors';
import * as PSSelectors from 'modules/projectSuppliers/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onRefreshRates: Actions.PS_REFRESH_RATES_CLICKED,
};

const mapStateToProps = state => {
    return {
        currenciesLastUpdated: SettingsSelectors.currenciesLastUpdated(state),
        updatingCurrencies: SettingsSelectors.updatingCurrencies(state),
        rate: ProjectsSelectors.selectedSupplierCurrencyRate(state),
        project: ProjectsSelectors.selectedProject(state),
        payments: PSSelectors.payments(state),
        justSubmitted: PSSelectors.justSubmitted(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
