import {put, takeLatest, call} from 'redux-saga/effects';
import {Actions} from 'modules/users/actions';
import axios from 'services/axios';
import {ActionTypes as PagesActions} from 'pages/actions';
import _ from 'lodash';


function* loadUsers(action) {
    yield put(Actions.LOAD_USERS_REQUEST());
    try {
        const {data} = yield call(axios.get, `users`);
        yield put(Actions.LOAD_USERS_SUCCESS(data));
    } catch (err) {
        yield put(Actions.LOAD_USERS_FAILURE(err));
    }
}

function* submitUserForm(action) {
    yield put(Actions.USER_FORM_SUBMIT_REQUEST());
    try {
        const {payload} = action;
        const userId = payload.id;
        const fields = _.pick(payload, ['name', 'username', 'password']);
        const method = userId ? axios.patch : axios.post;
        const url = userId ? `users/${userId}` : `users`;
        const result = yield call(method, url, fields);

        yield put(Actions.USER_FORM_SUBMIT_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.USER_FORM_SUBMIT_FAILURE(err));
    }
}

function* deleteUser(action) {
    yield put(Actions.DELETE_USER_SUBMIT_REQUEST());
    try {
        const {id} = action.payload;
        const result = yield call(axios.delete, `users/${id}`);

        yield put(Actions.DELETE_USER_SUBMIT_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.DELETE_USER_SUBMIT_FAILURE(err));
    }
}

const sagas = [
    takeLatest([PagesActions.USERS_PAGE_MOUNTED], loadUsers),
    takeLatest([PagesActions.USER_FORM_SUBMITTED], submitUserForm),
    takeLatest([PagesActions.USERS_PAGE_DELETE_USER], deleteUser),
]
export default sagas;
