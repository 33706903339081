import React from "react";
import MUIDataTable from "mui-datatables";
import {
  Breadcrumbs, Tooltip, IconButton, Link,
  FormControlLabel, Typography, Switch, Grid, FormControl, RadioGroup, Radio,
} from '@material-ui/core';

import { Link as RouterLink } from "react-router-dom";

import {
  Add as AddIcon,
  List as ListIcon,
  GridOn as GridOnIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  People as PeopleIcon,
  Edit as EditIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';

// components
import PageTitle from "../../components/PageTitle";
import {activityTypeFormatter, muiDataTableLabels} from 'utils/i18nUtils'
import Loader from "components/Loader/Loader";
import { withStyles } from "@material-ui/styles";
import { centralizeColumn } from "utils/materialUtils";
import { priceFormatter } from "utils/formatters";
import ProjectSupplierSummary from "components/ProjectSupplierSummary";
import PSPayments from "components/PSPayments";
import CreateEditActivity from "components/CreateEditActivity";
import BulkEditActivities from "components/BulkEditActivities";
import BulkAddActivities from "components/BulkAddActivities";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";


class ProjectSupplier extends React.Component {
  state = {
    activityDialogOpen: false,
    bulkEditActivityDialogOpen: false,
    bulkAddActivityDialogOpen: false,
    reportLanguageDialogOpen: false,
    reportLanguage: 'he',
    confirmation: {open: false},
  }
  componentDidMount = () => {
    this.props.onMount();
  }
  
  componentDidUpdate = (oldProps) => {
    if (!oldProps.justSubmitted && this.props.justSubmitted) {
      this.setState({justSubmitted: true})
    }
  }

  handleShowArchivedChange = (e) => {
    const isHidden = e.target.checked;
    this.props.onShowHiddenChange(isHidden);
  }

  handleEditIcon = (activity) => {
    this.setState({selectedActivity: activity, activityDialogOpen: true, justSubmitted: false});
  }

  handleActivityDialogClose = () => {
    this.setState({activityDialogOpen: false, justSubmitted: false});
  }

  handleBulkActivityDialogClose = () => {
    this.setState({bulkEditActivityDialogOpen: false, justSubmitted: false});
  }

  handleBulkAddActivityDialogClose = () => {
    this.setState({bulkAddActivityDialogOpen: false, justSubmitted: false});
  }

  handleDownloadReportDialogConfirmed = () => {
    this.setState({reportLanguageDialogOpen: false});
    this.props.onDownloadReport({lang: this.state.reportLanguage})
  }

  handleActivityChangeState = (activity) => {
    this.closeConfirmationDialog();
    this.props.onActivityChangeState(activity)
  }

  closeConfirmationDialog = () => {
    this.setState({confirmation: {open: false}});
  }

  confirmChangeActivityState = (activity) => {
    const activateTexts = {
      title: `הוצאת ביצוע מארכיון - ${activity.name}`,
      subtitle: 'האם אתה בטוח כי ברצונך להוציא ביצוע זה מארכיון?',
      confirmText: 'הוצאה מארכיון',
    }
    const archiveTexts = {
      title: `העברת ביצוע לארכיון - ${activity.name}`,
      subtitle: 'האם אתה בטוח כי ברצונך להעביר ביצוע זה לארכיון?',
      confirmText: 'העברה לארכיון',
    }
    const confirmation = {
      open: true,
      ...(activity.active ? archiveTexts : activateTexts),
      onConfirm: () => this.handleActivityChangeState(activity),
      onCancel: this.closeConfirmationDialog
    }
    this.setState({confirmation})
  }

  renderConfirmDialog = () => {
    return (
      <ConfirmDialog
          {...this.state.confirmation}
      />
    );
  }

  renderToolbar = () => {
    const {showArchived} = this.props.filter || {};

    return (
      <>
        <FormControlLabel
          control={<Switch checked={showArchived} onChange={this.handleShowArchivedChange} name="checkedA" />}
          label="הצג ביצועים מוסתרים"
        />
        <Tooltip arrow title="הורדת אקסל">
          <IconButton aria-label="הורדת אקסל" onClick={() => this.setState({reportLanguageDialogOpen: true})}>
            <GetAppIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="הוספת ביצועים מאקסל">
          <IconButton aria-label="הוספת ביצועים מאקסל" onClick={e => this.setState({bulkAddActivityDialogOpen: true})}>
            <GridOnIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="עריכה מהירה">
          <IconButton aria-label="עריכה מהירה" onClick={e => this.setState({bulkEditActivityDialogOpen: true})}>
            <ListIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="הוספת ביצוע חדש">
          <IconButton aria-label="הוספת ביצוע חדש" onClick={() => this.handleEditIcon()}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderActionsColumn = (dataIndex) => {
    const {classes, activities} = this.props;
    const activity = activities[dataIndex]
    if (!activity) {return;}

    const Icon = activity.active ? ArchiveIcon : UnarchiveIcon;
    const text = activity.active ? 'העבר לארכיון' : 'הפוך לפעיל';
    return (
      <div className={classes.actionsWrapper}>
        <Tooltip arrow title={text}>
          <IconButton aria-label={text} className={classes.actionIcon} onClick={() => this.confirmChangeActivityState(activity)}>
            <Icon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="עריכת ביצוע">
          <IconButton aria-label="עריכת ביצוע" className={classes.actionIcon} onClick={() => this.handleEditIcon(activity)}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  renderBreadcrumbs = (project) => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} color="inherit" to="/app/projects">
          כל הפרויקטים
        </Link>
        <Link component={RouterLink} color="inherit" to={`/app/projects/${project.id}`}>
          {project.name}
        </Link>
        <Typography color="textPrimary">{project.supplier.name}</Typography>
      </Breadcrumbs>
    );
  }

  renderPercentage = (percentage) => {
    return `${percentage}%`;
  }

  render() {
    const {project, loading, classes, activities} = this.props;
    const {
      activityDialogOpen, selectedActivity, justSubmitted,
      bulkEditActivityDialogOpen, bulkAddActivityDialogOpen,
      reportLanguageDialogOpen, reportLanguage,
    } = this.state;

    if (loading) {
      return (
        <Loader loading />
      )
    }
    if (!project || !project.supplier) {
      return 'נראה שיש בעיה. יש לפנות למנהל המערכת';
    }

    const priceOptions = {customBodyRender: p => priceFormatter(p)};
    return (
      <>
        {this.renderBreadcrumbs(project)}
        <PageTitle title={project.supplier.name} icon={PeopleIcon} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <MUIDataTable 
                title={"רשימת ביצועים"}
                data={activities}
                columns={[
                  {name: 'id', options: {display: 'false'}},
                  {name: 'number', label: '#', options: {customBodyRenderLite: (_, index) => index + 1}},
                  {name: 'name', label: 'פירוט הביצוע', options: {setCellProps: () => ({className: classes.textColumnWrapper})}},
                  {name: 'quantity', label: 'כמות'},
                  {name: 'unit_desc', label: 'יחידה'},
                  {name: 'price', label: 'מחיר ליחידה', options: {customBodyRender: p => priceFormatter(p, false)}},
                  {name: 'rawAmount', label: 'סכום ביניים', options: priceOptions},
                  {name: 'discount', label: 'הנחה', options: {customBodyRender: this.renderPercentage}},
                  {name: 'completed', label: 'ביצוע', options: {customBodyRender: this.renderPercentage}},
                  {name: 'lien', label: 'עיכבון', options: {customBodyRender: this.renderPercentage}},
                  {name: 'debtAmount', label: 'יתרה לתשלום', options: priceOptions},
                  {name: 'type', label: 'סוג', options: {customBodyRender: activityTypeFormatter}},
                  {
                    name: 'actions',
                    label: 'פעולות',
                    options: {
                      filter: false,
                      sort: false,
                      viewColumns: false,
                      customBodyRenderLite: this.renderActionsColumn,
                      ...centralizeColumn
                    }
                  },
                ]}
                options={{
                  filterType: "textField",
                  sortOrder: {name: 'id', direction: 'asc'},
                  textLabels: muiDataTableLabels,
                  selectableRows: 'none',
                  pagination: false,
                  filter: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  search: false,
                  searchOpen: true,
                  responsive: 'standard',
                  customToolbar: this.renderToolbar,
                  tableBodyMaxHeight: '700px'
                }}
              />
          </Grid>
          <Grid container item xs={12} md={3} spacing={2}>
            <Grid item xs={12}>
              <PSPayments />
            </Grid>
            <Grid item xs={12}>
              <ProjectSupplierSummary project={project} />
            </Grid>
          </Grid>
        </Grid>
        <CreateEditActivity
          justSubmitted={justSubmitted}
          open={activityDialogOpen}
          activity={selectedActivity}
          onClose={this.handleActivityDialogClose}
        />
        <BulkEditActivities
          justSubmitted={justSubmitted}
          open={bulkEditActivityDialogOpen}
          activities={activities}
          onClose={this.handleBulkActivityDialogClose}
        />
        <BulkAddActivities
          justSubmitted={justSubmitted}
          open={bulkAddActivityDialogOpen}
          onClose={this.handleBulkAddActivityDialogClose}
        />
        {this.renderConfirmDialog()}
        <ConfirmDialog
          open={reportLanguageDialogOpen}
          title={`יצירת דו"ח`}
          subtitle={`באיזו שפה יש ליצור את הדו"ח?`}
          confirmText={`יצירת דו"ח`}
          onConfirm={this.handleDownloadReportDialogConfirmed}
          onCancel={() => this.setState({reportLanguageDialogOpen: false})}
          customContent={
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="report-language"
                name="lang"
                value={reportLanguage}
                onChange={(e) => this.setState({reportLanguage: e.target.value})}
              >
                <FormControlLabel value="he" control={<Radio color="primary" />} label="עברית" />
                <FormControlLabel value="en" control={<Radio color="primary" />} label="אנגלית" />

              </RadioGroup>
            </FormControl>
          }
        />
      </>
    );
  }
}

const styles = theme => ({
  actionIcon: {
    padding: theme.spacing(1),
  },
  summaryTable: {
    marginTop: theme.spacing(4),
  },
  actionsWrapper: {
    minWidth: 80,
  },
  textColumnWrapper: {
    minWidth: 150,
  },
})

export default withStyles(styles, { withTheme: true })(ProjectSupplier);
