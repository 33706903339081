import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_PROJECTS_REQUEST',
    'FETCH_PROJECTS_SUCCESS',
    'FETCH_PROJECTS_FAILURE',
    
    'ADD_PROJECT_REQUEST',
    'ADD_PROJECT_SUCCESS',
    'ADD_PROJECT_FAILURE',
    
    'TOGGLE_PROJECT_STATE_REQUEST',
    'TOGGLE_PROJECT_STATE_SUCCESS',
    'TOGGLE_PROJECT_STATE_FAILURE',
    
    'FETCH_S_PROJECT_REQUEST',
    'FETCH_S_PROJECT_SUCCESS',
    'FETCH_S_PROJECT_FAILURE',
    
    'FETCH_S_ACTIVITIES_REQUEST',
    'FETCH_S_ACTIVITIES_SUCCESS',
    'FETCH_S_ACTIVITIES_FAILURE',
    
    'PROJECT_FORM_SUBMIT_REQUEST',
    'PROJECT_FORM_SUBMIT_SUCCESS',
    'PROJECT_FORM_SUBMIT_FAILURE',
    
    'EDIT_PROJECT_SUPPLIER_REQUEST',
    'EDIT_PROJECT_SUPPLIER_SUCCESS',
    'EDIT_PROJECT_SUPPLIER_FAILURE',
    
    'PROJECT_CHANGE_STATE_REQUEST',
    'PROJECT_CHANGE_STATE_SUCCESS',
    'PROJECT_CHANGE_STATE_FAILURE',
    
    'PROJECT_SUPPLIER_CHANGE_STATE_REQUEST',
    'PROJECT_SUPPLIER_CHANGE_STATE_SUCCESS',
    'PROJECT_SUPPLIER_CHANGE_STATE_FAILURE',
    
    'PROJECT_GET_REPORT_REQUEST',
    'PROJECT_GET_REPORT_SUCCESS',
    'PROJECT_GET_REPORT_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
