
export const muiDataTableLabels = {
    body: {
        noMatch: "סליחה. לא נמצאו תוצאות",
        toolTip: "מיון",
        columnHeaderTooltip: column => `מיון לפי ${column.label}`
      },
      pagination: {
        next: "הדף הבא",
        previous: "הדף הקודם",
        rowsPerPage: "שורות לדף:",
        displayRows: "מ-",
      },
      toolbar: {
        search: "חיפוש",
        downloadCsv: "הורד נתונים",
        print: "הדפס",
        viewColumns: "בחירת עמודות",
        filterTable: "סינון",
      },
      filter: {
        all: "כל",
        title: "סינונים",
        reset: "איפוס",
      },
      viewColumns: {
        title: "הצג עמודות",
        titleAria: "הצג/הסתר עמודות טבלה",
      },
      selectedRows: {
        text: "שורות נבחרו",
        delete: "מחיקה",
        deleteAria: "מחק שורות נבחרות",
      },
}

export function currencyFormatter(currency, long) {
  switch (currency) {
    case 'ILS':
      return long ? 'ש"ח (₪)' : '₪';
    case 'USD':
      return long ? 'דולר ($)' : '$';
    case 'EUR':
      return long ? 'אירו (€)' : '€';
    default:
      return 'Invalid Currency';
  }
}

export function  activityTypeFormatter(activityType) {
  switch (activityType) {
    case 'contract':
      return 'חוזה'
    case 'additions':
      return 'תוספות'
    default:
      return 'Invalid type';
  }
}
