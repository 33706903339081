import { createSelector } from 'reselect';
import _ from 'lodash'

export const sliceSelector = (state) => state.settings;
export const isLoading = createSelector(sliceSelector, slice => slice.loading);
export const isSubmitting = createSelector(sliceSelector, slice => slice.submitting);
export const currencies = createSelector(sliceSelector, slice => slice.currencies || []);
export const currenciesLastUpdated = createSelector(sliceSelector, slice => slice.currenciesLastUpdated);
export const updatingCurrencies = createSelector(sliceSelector, slice => !!slice.updatingCurrencies);
export const settings = createSelector(sliceSelector, slice => _.pick(slice, ['vat']));
