import { combineReducers } from 'redux';
import {reducer as projectsReducer, Sagas as projectsSagas} from 'modules/projects';
import {reducer as authReducer, Sagas as authSagas} from 'modules/auth';
import {reducer as suppliersReducer, Sagas as suppliersSagas} from 'modules/suppliers';
import {reducer as settingsReducer, Sagas as settingsSagas} from 'modules/settings';
import {reducer as projectSuppliersReducer, Sagas as projectSuppliersSagas} from 'modules/projectSuppliers';
import {reducer as usersReducer, Sagas as usersSagas} from 'modules/users';
import { connectRouter } from 'connected-react-router'
import { all } from 'redux-saga/effects'

export const getRootReducer = (options) => combineReducers({
  router: connectRouter(options.history),
  projects: projectsReducer,
  auth: authReducer,
  suppliers: suppliersReducer,
  settings: settingsReducer,
  projectSuppliers: projectSuppliersReducer,
  users: usersReducer,
})

export function * rootSaga() {
  yield all([
      ...projectsSagas,
      ...authSagas,
      ...suppliersSagas,
      ...settingsSagas,
      ...projectSuppliersSagas,
      ...usersSagas,
  ])
}