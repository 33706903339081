import React from 'react';
import PropTypes from 'prop-types';
import {LinearProgress, Typography, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useProgressStyles = makeStyles(theme => ({
  root: {
    height: 10,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 300 : 700],
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useProgressStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} classes={classes} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
