import {connect} from 'utils/reduxUtils';
import Component from './CreateEditUser';
import * as UsersSelectors from 'modules/users/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onSubmit: Actions.USER_FORM_SUBMITTED,
};

const mapStateToProps = state => {
    return {
        loading: UsersSelectors.isSubmitting(state),
        submitError: UsersSelectors.submitError(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
