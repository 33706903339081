import React, {useEffect, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, CircularProgress, DialogTitle,
} from '@material-ui/core';

import {Formik} from 'formik';
import TextField from 'components/Form/TextField';
import Yup from 'services/yup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import { Alert } from '@material-ui/lab';

const fieldsValidations = {
  name: Yup.string().required('זהו שדה חובה'),
  phone: Yup.string(),
  email: Yup.string(),
  location: Yup.string(),
  bank: Yup.string(),
};

const validationSchema = Yup.object().shape(fieldsValidations);
const emptySupplier = _.transform(_.keys(fieldsValidations), (aggr, key) => aggr[key] = '', {});

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  }
}));

export default function CreateEditSupplier({open, onClose, onSubmit, loading, justSubmitted, submitError, supplier = _.clone(emptySupplier)}) {
  const [supplierVal, setSupplierVal] = useState(_.clone(supplier || emptySupplier));
  useEffect(() => {
    if (open && justSubmitted) {
      onClose();
    }
  }, [justSubmitted, onClose, open])
  
  useEffect(() => {
    if (!_.isEqual(supplierVal, supplier)) {
      setSupplierVal(_.clone(supplier || emptySupplier))
    }
  }, [supplier, supplierVal])

  const classes = useStyles();
  const texts = {
    title: supplierVal.id ? `עריכת ספק - ${supplier.name}` : 'יצירת ספק חדש',
    subtitle: supplierVal.id ? 'עריכת פרטי הספק:' : 'כדי ליצור ספק חדש יש למלא את הפרטים הבאים:',
    action: supplierVal.id ? 'עריכה' : 'יצירה',
  }

  return (
  <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="submit-supplier-title" disableBackdropClick disableEscapeKeyDown={loading}>
    <DialogTitle classes={{root: classes.dialogTitle}} id="submit-supplier-title">{texts.title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{texts.subtitle}</DialogContentText>
      {open && (<Formik
        initialValues={supplierVal}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {handleSubmit} = props;
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                fieldProps={{
                  label: 'שם הספק',
                  autoFocus: true,
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="phone"
                fieldProps={{
                  label: 'טלפון',
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="email"
                fieldProps={{
                  label: 'אימייל',
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="location"
                fieldProps={{
                  label: 'מיקום',
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="bank"
                fieldProps={{
                  multiline: true,
                  label: 'חשבון בנק',
                  InputProps: {readOnly: loading},
                }}
              />
              {submitError && <Alert severity="error">{submitError}</Alert>}
              <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                  ביטול
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {texts.action}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
      )}
    </DialogContent>
  </Dialog>
  );
}