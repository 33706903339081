import {connect} from 'utils/reduxUtils';
import Component from './CreateEditProjectSupplier';
import * as ProjectsSelectors from 'modules/projects/selectors';
import * as SuppliersSelectors from 'modules/suppliers/selectors';
import * as SettingsSelectors from 'modules/settings/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onSubmit: Actions.PROJECT_SUPPLIER_FORM_SUBMITTED,
};

const mapStateToProps = state => {
    return {
        loading: ProjectsSelectors.isSubmitting(state),
        suppliers: SuppliersSelectors.suppliersNames(state),
        currencies: SettingsSelectors.currencies(state),
        submitError: ProjectsSelectors.submitError(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
