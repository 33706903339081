import {ActionTypes} from './actions';

const initialValue = {
    filter: {showArchived: false},
    singleFilter: {showArchived: false},
}

const currencies = {
    USD: 'דולר',
    ILS: 'ש"ח',
    EUR: 'אירו',
}

const settingsReducer = (state = initialValue, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.UPDATE_CURRENCIES_REQUEST: 
        nextState = {...state, updatingCurrencies: true};
        break;
    case ActionTypes.UPDATE_CURRENCIES_FAILURE: 
        nextState = {...state, updatingCurrencies: false};
        break;
    case ActionTypes.UPDATE_CURRENCIES_SUCCESS:
    nextState = {
        ...state,
        ...payload,
        currencies: payload.currencies.map(i => ({...i, name: currencies[i.code]})),
        updatingCurrencies: false,
    };
    break;
    case ActionTypes.UPDATE_SETTINGS_REQUEST: 
        nextState = {...state, submitting: true};
        break;
    case ActionTypes.UPDATE_SETTINGS_FAILURE: 
        nextState = {...state, submitting: false};
        break;
    case ActionTypes.UPDATE_SETTINGS_SUCCESS:
        nextState = {...state, ...payload, submitting: false};
        break;
    case ActionTypes.LOAD_SETTINGS_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.LOAD_SETTINGS_SUCCESS:
        nextState = {
            ...state,
            ...payload,
            loading: false,
            currencies: payload.currencies.map(i => ({...i, name: currencies[i.code]})),
        };
        break;
    default:
        nextState = state;
    }
    return nextState;
};

export default settingsReducer;
