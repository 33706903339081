import { createSelector } from 'reselect';
import * as NavSelectors from 'modules/navigation/selectors';
import * as SettingsSelectors from 'modules/settings/selectors';

export const sliceSelector = (state) => state.projects;
export const projects = createSelector(sliceSelector, slice => slice.projects || []);
export const selectedProject = createSelector(sliceSelector, slice => slice.selectedProject);
export const isLoading = createSelector(sliceSelector, slice => !!slice.loading);
export const isSubmitting = createSelector(sliceSelector, slice => !!slice.submitting);
export const justSubmitted = createSelector(sliceSelector, slice => !!slice.justSubmitted);
export const submitError = createSelector(sliceSelector, slice => slice.submitError);

export const filter = createSelector(sliceSelector, slice => slice.filter);
export const singleFilter = createSelector(sliceSelector, slice => slice.singleFilter);
export const selectedSupplier = createSelector(selectedProject, project => project.supplier);
export const selectedSupplierCurrency = createSelector(selectedSupplier, supplier => supplier && supplier.currency);
export const selectedSupplierCurrencyRate = createSelector(selectedSupplierCurrency, SettingsSelectors.currencies,
    (currency, currencies) => {
        const matchingCurrency = currencies.find(c => c.code === currency);
        return matchingCurrency && matchingCurrency.rate;
    }
);

export const matchingProjectId = createSelector(
    NavSelectors.pathnameSelector,
    (pathname) => {
        const matchedRegex = (pathname || '').match(/\/projects\/([^/?]*)/) || [];
        const id = matchedRegex[1];
        return parseInt(id);
    }
);
export const matchingSupplierId = createSelector(
    NavSelectors.pathnameSelector,
    (pathname) => {
        const matchedRegex = (pathname || '').match(/\/projects\/[^/?]*\/suppliers\/([^/?]*)/) || [];
        const id = matchedRegex[1];
        return parseInt(id);
    }
);