import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    conversionToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    btnLoaderWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    tableRow: {
        height: 30,
    },
    tableCell: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    toolbar: {
        minHeight: 50,
    },
    toolbarCell: {
        paddingLeft: 0,
    }
}));