import {connect} from 'utils/reduxUtils';
import Component from './SingleProject';
import * as ProjectsSelectors from 'modules/projects/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onMount: Actions.S_PROJECT_PAGE_MOUNTED,
    onShowHiddenChange: Actions.S_PROJECT_PAGE_ARCHIVED_TOGGLED,
    onSupplierChangeState: Actions.S_PROJECT_PAGE_TOGGLE_SUPPLIER_STATE,
    onDownloadReport: Actions.S_PROJECT_PAGE_DOWNLOAD_REPORT,
};

const mapStateToProps = state => {
    return {
        loading: ProjectsSelectors.isLoading(state),
        filter: ProjectsSelectors.singleFilter(state),
        project: ProjectsSelectors.selectedProject(state),
        justSubmitted: ProjectsSelectors.justSubmitted(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
