import React from 'react';
import {Field, useFormikContext } from 'formik';
import MuiTextField from '@material-ui/core/TextField';
import {Autocomplete as FormikAutoComplete} from 'formik-material-ui-lab';
import _ from 'lodash';

export default function AutoComplete({fieldProps = {}, name, label, options = [], ...props}) {
  const context = useFormikContext();
  const error = (context.touched[name] && context.errors[name]);
  const allOptions = [{value: '', label: ''}, ...options]
  return (
    <Field
      name={name}
      id={name}
      component={FormikAutoComplete}
      options={allOptions.map(opt => opt.value)}
      getOptionLabel={(value) => {
        return _.get(_.find(allOptions, {value}), 'label') || '';
      }}
      getOptionSelected={(option, value) => {
        return option === value;
      }}
      fullWidth
      renderInput={(params) => (
        <MuiTextField 
          error={!!error}
          margin="normal"
          fullWidth
          {...fieldProps}
          variant="outlined"
          label={label}
          helperText={error || fieldProps.helperText}
          {...params}
        />
      )}
      {...props}
    />
  );
}