import React, {useEffect, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, CircularProgress, DialogTitle,
} from '@material-ui/core';

import {Formik} from 'formik';
import TextField from 'components/Form/TextField';
import Yup from 'services/yup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import { Alert } from '@material-ui/lab';

const baseValidation = {
  name: Yup.string().required('זהו שדה חובה'),
  username: Yup.string().email('יש להקליד אימייל חוקי').required('זהו שדה חובה'),
  password: Yup.string().matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
    "הסיסמה חייבת להכיל לפחות 8 תוים, אות גדולה, אות קטנה ותו מיוחד (@$!%*#?&)"
  ),
}
const addValidation = {
  ...baseValidation,
  password: baseValidation.password.required('זהו שדה חובה'),
};
const updateValidation = {
  ...baseValidation,
};

const addValidationSchema = Yup.object().shape(addValidation);
const updateValidationSchema = Yup.object().shape(updateValidation);
const emptyUser = _.transform(_.keys(baseValidation), (aggr, key) => aggr[key] = '', {});

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  }
}));

export default function CreateEditUser({open, onClose, onSubmit, submitError, loading, justSubmitted, user = _.clone(emptyUser)}) {
  const [userVal, setUserVal] = useState(_.clone(user || emptyUser));
  useEffect(() => {
    if (open && justSubmitted) {
      onClose();
    }
  }, [justSubmitted, onClose, open])
  
  useEffect(() => {
    if (!_.isEqual(userVal, user)) {
      setUserVal(_.clone(user || emptyUser))
    }
  }, [user, userVal])

  const classes = useStyles();
  const texts = {
    title: userVal.id ? `עריכת משתמש - ${user.name}` : 'יצירת משתמש חדש',
    subtitle: userVal.id ? 'עריכת פרטי המשתמש:' : 'כדי ליצור משתמש חדש יש למלא את הפרטים הבאים:',
    action: userVal.id ? 'עריכה' : 'יצירה',
  }
  const validationSchema = userVal.id ? updateValidationSchema : addValidationSchema;
  return (
  <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="submit-user-title" disableBackdropClick disableEscapeKeyDown={loading}>
    <DialogTitle classes={{root: classes.dialogTitle}} id="submit-user-title">{texts.title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{texts.subtitle}</DialogContentText>
      {open && (<Formik
        initialValues={userVal}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {handleSubmit} = props;
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                fieldProps={{
                  label: 'שם',
                  autoFocus: true,
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="username"
                fieldProps={{
                  label: 'אימייל',
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="password"
                fieldProps={{
                  label: 'סיסמה',
                  InputProps: {readOnly: loading},
                }}
              />
              {submitError && <Alert severity="error">{submitError}</Alert>}
              <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                  ביטול
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {texts.action}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
      )}
    </DialogContent>
  </Dialog>
  );
}