import {put, takeLatest, call, select} from 'redux-saga/effects';
import * as Selectors from 'modules/projectSuppliers/selectors';
import * as ProjectsSelectors from 'modules/projects/selectors';
import {Actions} from 'modules/projectSuppliers/actions';
import {ActionTypes as PagesActions} from 'pages/actions';
import axios from 'services/axios';
import fileSaver from 'file-saver';
import _ from 'lodash';
import { s2ab } from 'utils/transformers';

function* fetchSupplierPayments(action) {
    yield put(Actions.FETCH_SUPPLIER_PAYMENTS_REQUEST());
    try {
        const projectSupplierID = yield select(Selectors.matchingSupplierId);
        const {data} = yield call(axios.get, `projectSuppliers/${projectSupplierID}/payments`)
        yield put(Actions.FETCH_SUPPLIER_PAYMENTS_SUCCESS(data));
    } catch (err) {
        yield put(Actions.FETCH_SUPPLIER_PAYMENTS_FAILURE(err));
    }
}

function* submitPaymentForm(action) {
    yield put(Actions.PAYMENT_FORM_SUBMIT_REQUEST());
    try {
        const {payload} = action;
        const paymentId = payload.id;
        const psId = yield select(Selectors.matchingSupplierId);

        const fields = _.pick(payload, ['amount', 'vat', 'rate', 'date']);
        const method = paymentId ? axios.patch : axios.post;
        const url = paymentId ? `projectSuppliers/${psId}/payments/${paymentId}` : `projectSuppliers/${psId}/payments`;
        const result = yield call(method, url, fields);

        yield put(Actions.PAYMENT_FORM_SUBMIT_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.PAYMENT_FORM_SUBMIT_FAILURE(err));
    }
}

function* submitActivityForm(action) {
    yield put(Actions.ACTIVITY_FORM_SUBMIT_REQUEST());
    try {
        const {payload} = action;
        const activityId = payload.id;
        const psId = yield select(Selectors.matchingSupplierId);

        const fields = _.pick(payload, ['name', 'type', 'quantity', 'price', 'unit_desc', 'discount', 'lien', 'completed']);
        const method = activityId ? axios.patch : axios.post;
        const url = activityId ? `projectSuppliers/${psId}/activities/${activityId}` : `projectSuppliers/${psId}/activities`;
        const reqPayload = activityId ? fields : {data: [fields]};
        const result = yield call(method, url, reqPayload);
        const dispatchPayload = activityId ? [result.data] : result.data.items;
        yield put(Actions.ACTIVITY_FORM_SUBMIT_SUCCESS(dispatchPayload));
    } catch (err) {
        yield put(Actions.ACTIVITY_FORM_SUBMIT_FAILURE(err));
    }
}

function* submitBulkAddActivityForm(action) {
    yield put(Actions.ACTIVITY_BULK_ADD_FORM_SUBMIT_REQUEST());
    try {
        const {payload} = action;
        const psId = yield select(Selectors.matchingSupplierId);
        const fieldRows = payload.map(p => _.pick(p, ['name', 'type', 'quantity', 'price', 'unit_desc', 'discount', 'lien', 'completed']))

        const result = yield call(axios.post, `projectSuppliers/${psId}/activities`, {data: fieldRows});

        yield put(Actions.ACTIVITY_BULK_ADD_FORM_SUBMIT_SUCCESS(result.data.items));
    } catch (err) {
        yield put(Actions.ACTIVITY_BULK_ADD_FORM_SUBMIT_FAILURE(err));
    }
}

function* submitBulkEditActivityForm(action) {
    yield put(Actions.ACTIVITY_BULK_FORM_SUBMIT_REQUEST());
    try {
        const {payload} = action;
        const psId = yield select(Selectors.matchingSupplierId);

        const data = payload.map(p => _.pick(p, ['id', 'name', 'type', 'quantity', 'price', 'unit_desc', 'discount', 'lien', 'completed']));
        const result = yield call(axios.patch, `projectSuppliers/${psId}/activities`, {data});

        yield put(Actions.ACTIVITY_BULK_FORM_SUBMIT_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.ACTIVITY_BULK_FORM_SUBMIT_FAILURE(err));
    }
}

function* fetchActivities(action) {
    yield put(Actions.FETCH_ACTIVITIES_REQUEST());
    try {
        const {showArchived} = yield select(Selectors.activitiesFilter);
        const query = `active=${!showArchived}`;
        const supplierId = yield select(Selectors.matchingSupplierId);
        const {data} = yield call(axios.get, `projectSuppliers/${supplierId}/activities?${query}`);
        yield put(Actions.FETCH_ACTIVITIES_SUCCESS(data));
    } catch (err) {
        yield put(Actions.FETCH_ACTIVITIES_FAILURE(err));
    }
}

function* downloadSupplierReport(action) {
    try {
        const {lang} = action.payload;
        const psId = yield select(Selectors.matchingSupplierId);
        const project = yield select(ProjectsSelectors.selectedProject);
        yield put(Actions.PROJECT_SUP_GET_REPORT_REQUEST());
        const result = yield call(axios.post, `projectSuppliers/${psId}/prepareReport?lang=${lang}`);
        const currentDate = new Date().toJSON().slice(0,10).split('-').reverse().join('.');
        fileSaver.saveAs(new Blob([s2ab(atob(result.data))], {type: result.headers['content-type']}), `${project.supplier.name} - חשבון לתשלום - ${currentDate}.xlsx`);
        yield put(Actions.PROJECT_SUP_GET_REPORT_SUCCESS());
    } catch (err) {
        yield put(Actions.PROJECT_SUP_GET_REPORT_FAILURE(err));
    }
}

function* changeActivityState(action) {
    yield put(Actions.ACTIVITY_CHANGE_STATE_REQUEST());
    try {
        const {payload} = action;
        const activityId = payload.id;
        const psId = yield select(Selectors.matchingSupplierId);
        const result = yield call(axios.patch, `projectSuppliers/${psId}/activities/${activityId}`, {active: !payload.active});

        yield put(Actions.ACTIVITY_CHANGE_STATE_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.ACTIVITY_CHANGE_STATE_FAILURE(err));
    }
}


const sagas = [
    takeLatest(PagesActions.PROJECT_SUP_PAGE_MOUNTED, fetchSupplierPayments),
    takeLatest([PagesActions.PROJECT_SUP_PAGE_MOUNTED, PagesActions.ACTIVITIES_ARCHIVED_STATE_TOGGLED], fetchActivities),
    takeLatest(PagesActions.PAYMENT_FORM_SUBMITTED, submitPaymentForm),
    takeLatest(PagesActions.ACTIVITY_FORM_SUBMITTED, submitActivityForm),
    takeLatest(PagesActions.ACTIVITY_BULK_EDIT_FORM_SUBMITTED, submitBulkEditActivityForm),
    takeLatest(PagesActions.ACTIVITY_BULK_ADD_FORM_SUBMITTED, submitBulkAddActivityForm),
    takeLatest(PagesActions.PROJ_SUP_PAGE_DOWNLOAD_REPORT, downloadSupplierReport),
    takeLatest(PagesActions.PROJ_SUP_PAGE_TOGGLE_ACTIVITY_STATE, changeActivityState),
]
export default sagas;
