import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import ProjectsPage from "../../pages/projects";
import SuppliersPage from "../../pages/suppliers";
import ProjectPage from "../../pages/singleProject";
import ProjectSupplierPage from "../../pages/projectSupplier";
import SettingsPage from "pages/settings";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Error from "../../pages/error";

// context
import { useLayoutState } from "../../context/LayoutContext";
import UsersPage from "pages/users";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/projects" component={ProjectsPage} exact />
              <Route path="/app/projects/:id/" exact render={({match}) => <Redirect to={`${match.url}/suppliers`} />} />
              <Route path="/app/projects/:id/suppliers" component={ProjectPage} exact />
              <Route path="/app/projects/:id/suppliers/:id" component={ProjectSupplierPage} exact />
              <Route path="/app/suppliers" component={SuppliersPage} exact />
              <Route path="/app/settings" component={SettingsPage} exact />
              <Route path="/app/users" component={UsersPage} exact />
              <Route path="/app/notifications" component={Notifications} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
              <Route component={Error} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
