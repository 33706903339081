import {connect} from 'utils/reduxUtils';
import Component from './UsersPage';
import * as UsersSelectors from 'modules/users/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onMount: Actions.USERS_PAGE_MOUNTED,
    onDeleteUser: Actions.USERS_PAGE_DELETE_USER,
};

const mapStateToProps = state => {
    return {
        loading: UsersSelectors.isLoading(state),
        justSubmitted: UsersSelectors.justSubmitted(state),
        submitting: UsersSelectors.isSubmitting(state),
        users: UsersSelectors.users(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
