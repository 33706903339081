import React from "react";
import {
  Grid, Table, TableBody, TableCell, TableContainer, TableRow,
  TableHead, Paper, Breadcrumbs, Tooltip, IconButton, Link,
  FormControlLabel, Typography, Switch, Toolbar,
} from '@material-ui/core';

import MUIDataTable from "mui-datatables";
import { Link as RouterLink } from "react-router-dom";


import {
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  Domain as DomainIcon,
  Edit as EditIcon,
  Add as AddIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';

// components
import PageTitle from "../../components/PageTitle";
import {currencyFormatter, muiDataTableLabels} from 'utils/i18nUtils'
import Loader from "components/Loader/Loader";
import { withStyles } from "@material-ui/styles";
import CreateEditProjectSupplier from "components/CreateEditProjectSupplier";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { centralizeColumn } from "utils/materialUtils";
import { priceFormatter } from "utils/formatters";


class ProjectsPage extends React.Component {
  constructor(props) {
    super(props);
    const priceOptions = {customBodyRender: p => priceFormatter(p)};
    this.state = {
      supplierDialogOpen: false,
      confirmation: {open: false},
      columns: [
        {name: 'name', label: 'שם הספק'},
        {name: 'contractAmount', label: 'סכום חוזה', options: priceOptions},
        {name: 'additionsAmount', label: 'סכום תוספות', options: priceOptions},
        {name: 'totalAmount', label: 'סה"כ', options: priceOptions},
        {name: 'paidAmount', label: 'שולם', options: priceOptions},
        {name: 'debtAmount', label: 'יתרה לתשלום', options: priceOptions},
        {name: 'finalDebtAmount', label: 'יתרת חוזה', options: priceOptions},
        {name: 'currency', label: 'מטבע', options: {customBodyRender: currencyFormatter}},
        {
          name: 'actions',
          label: 'פעולות',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: this.renderActionsColumn,
            ...centralizeColumn,
          }
        },
      ]
    }
  }
  componentDidMount = () => {
    this.props.onMount();
  }

  componentDidUpdate = (oldProps) => {
    if (!oldProps.justSubmitted && this.props.justSubmitted) {
      this.setState({justSubmitted: true})
    }
  }

  handleShowArchivedChange = (e) => {
    const isHidden = e.target.checked;
    this.props.onShowHiddenChange(isHidden);
  }

  handleCellClick = (_, {colIndex, dataIndex}) => {
    if (colIndex === this.state.columns.length -1) {
      return;
    }
    const {project, history, match} = this.props;
    const supplier = project.suppliers[dataIndex];
    history.push(`${match.url}/${supplier.id}`);
  }

  handleEditIcon = (project) => {
    this.setState({selectedSupplier: project, supplierDialogOpen: true, justSubmitted: false});
  }
  
  handleProjectDialogClose = () => {
    this.setState({supplierDialogOpen: false, justSubmitted: false});
  }

  closeConfirmationDialog = () => {
    this.setState({confirmation: {open: false}});
  }

  handleSupplierChangeState = (supplier) => {
    this.closeConfirmationDialog();
    this.props.onSupplierChangeState(supplier)
  }

  confirmChangeSupplierState = (supplier) => {
    const activateTexts = {
      title: `הוצאת ספק מארכיון - ${supplier.name}`,
      subtitle: 'האם אתה בטוח כי ברצונך להוציא ספק זה מארכיון?',
      confirmText: 'הוצאה מארכיון',
    }
    const archiveTexts = {
      title: `העברת ספק לארכיון - ${supplier.name}`,
      subtitle: 'האם אתה בטוח כי ברצונך להעביר ספק זה לארכיון?',
      confirmText: 'העברה לארכיון',
    }
    const confirmation = {
      open: true,
      ...(supplier.active ? archiveTexts : activateTexts),
      onConfirm: () => this.handleSupplierChangeState(supplier),
      onCancel: this.closeConfirmationDialog
    }
    this.setState({confirmation})
  }

  renderToolbar = () => {
    const {showArchived} = this.props.filter || {};

    return (
      <>
        <FormControlLabel
          control={<Switch checked={showArchived} onChange={this.handleShowArchivedChange} name="checkedA" />}
          label="הצג ספקים מוסתרים"
        />
        <Tooltip arrow title="הורדת אקסל">
          <IconButton aria-label="הורדת אקסל" onClick={() => this.props.onDownloadReport()}>
            <GetAppIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="הוספת ספק חדש">
          <IconButton aria-label="הוספת ספק חדש" onClick={() => this.handleEditIcon()}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderActionsColumn = (dataIndex) => {
    const {classes} = this.props;
    const {suppliers} = this.props.project;
    const supplier = suppliers[dataIndex]
    if (!supplier) {
      return null;
    }
    const Icon = supplier.active ? ArchiveIcon : UnarchiveIcon;
    const text = supplier.active ? 'העבר לארכיון' : 'הפוך לפעיל';
    return (
      <>
        <Tooltip arrow title={text}>
          <IconButton aria-label={text} className={classes.actionIcon} onClick={() => this.confirmChangeSupplierState(supplier)}>
            <Icon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="עריכת ספק">
          <IconButton aria-label="עריכת ספק" className={classes.actionIcon} onClick={() => this.handleEditIcon(supplier)}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderConfirmDialog = () => {
    return (
      <ConfirmDialog
          {...this.state.confirmation}
      />
    );
  }

  renderBreadcrumbs = (project) => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} color="inherit" to="/app/projects">
          כל הפרויקטים
        </Link>
        <Typography color="textPrimary">{project.name}</Typography>
      </Breadcrumbs>
    );
  }

  renderCellByKey = (summary, key, mapper, ...args) => {
    const {classes} = this.props;
    return summary.map(({[key]: raw}) => {
      const value = mapper ? mapper(raw, ...args) : raw;
      return (
        <TableCell key={value} className={classes.summaryCell}>{value}</TableCell>
      )
    });
  }

  renderSummary = (project) => {
    return (
      <TableContainer component={Paper} elevation={4}>
        <Toolbar>
          <Typography variant="h6" component="div">
            סיכום כללי
          </Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow hover>
              <TableCell></TableCell>
              {this.renderCellByKey(project.summary, 'currency', currencyFormatter, true)}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell variant="head">סכום חוזה</TableCell>
              {this.renderCellByKey(project.summary, 'contractAmount', priceFormatter)}
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">סכום תוספות</TableCell>
              {this.renderCellByKey(project.summary, 'additionsAmount', priceFormatter)}
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">סה"כ</TableCell>
              {this.renderCellByKey(project.summary, 'totalAmount', priceFormatter)}
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">שולם</TableCell>
              {this.renderCellByKey(project.summary, 'paidAmount', priceFormatter)}
            </TableRow>
            {/* <TableRow hover>
              <TableCell variant="head">יתרה לתשלום</TableCell>
              {this.renderCellByKey(project.summary, 'debtAmount', priceFormatter)}
            </TableRow> */}
            <TableRow hover>
              <TableCell variant="head">יתרת חוזה</TableCell>
              {this.renderCellByKey(project.summary, 'finalDebtAmount', priceFormatter)}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  render() {
    const {project, loading} = this.props;
    const {supplierDialogOpen, selectedSupplier, columns, justSubmitted} = this.state;

    if (loading) {
      return (
        <Loader loading />
      )
    }
    if (!project || !project.suppliers) {
      return 'נראה שיש בעיה. יש לפנות למנהל המערכת';
    }
    const {name} = project;
    return (
      <>
        {this.renderBreadcrumbs(project)}
        <PageTitle title={name} icon={DomainIcon} />
        <Grid container spacing={4}>
          <Grid item xs={12} lg={9}>
            <MUIDataTable
                title={"רשימת הספקים"}
                data={project.suppliers}
                columns={columns}
                options={{
                  sortOrder: {name: 'name', direction: 'asc'},
                  filterType: "textField",
                  onCellClick: this.handleCellClick,
                  textLabels: muiDataTableLabels,
                  selectableRows: 'none',
                  pagination: false,
                  filter: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  search: false,
                  searchOpen: true,
                  selectableRowsOnClick: true,
                  responsive: 'standard',
                  customToolbar: this.renderToolbar,
                  tableBodyMaxHeight: '700px'
                }}
              />
          </Grid>
          <Grid item xs={12} lg={3}>
            {this.renderSummary(project)}
          </Grid>
        </Grid>
        <CreateEditProjectSupplier
          justSubmitted={justSubmitted}
          open={supplierDialogOpen}
          supplier={selectedSupplier}
          onClose={this.handleProjectDialogClose}
        />
        {this.renderConfirmDialog()}
      </>
    );
  }
}

const styles = theme => ({
  actionIcon: {
    padding: theme.spacing(1),
  },
  summaryCell: {
    padding: 15,
  }
})

export default withStyles(styles, { withTheme: true })(ProjectsPage);
