import {connect} from 'utils/reduxUtils';
import Component from './CreateEditProject';
import * as ProjectsSelectors from 'modules/projects/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onSubmit: Actions.PROJECT_FORM_SUBMITTED,
};

const mapStateToProps = state => {
    return {
        loading: ProjectsSelectors.isSubmitting(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
