export const shallowCompare = (array1, array2, keys) => {
    if (!array1 && !array2) {return true;}
    if (!array1 || !array2) {return false;}
    if (array1.length !== array2.length) {return false;}
    return array1.every((item, index) => {
        const item2 = array2[index] 
        if (item === item2) {
            return true;
        }
        return keys.every(key => item[key] === item2[key])
    });

}