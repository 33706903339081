const config = {
    idleThrottle: 5,
    logoutThrottle: 1000,
    sessionIdleExpiration: 60 * 20,
    heartbitPollingTime: 5 * 60 * 1000,
    loginPaths: ['/login'],
    recaptchaKey: '6Le9nuUUAAAAAAueMQfU-7ABXdIeVS6hQb6pyQeQ',
    minPassLength: 6,
};
export default config;
