import { mergeItemsBy } from 'utils/reduxUtils';
import {ActionTypes} from './actions';
import {ActionTypes as PagesActionTypes} from 'pages/actions';

const initialValue = {
  activitiesFilter: {showArchived: false},
}

const projectsReducer = (state = initialValue, action) => {
  let nextState;
  const {type, payload} = action;
  switch (type) {
  case ActionTypes.FETCH_ACTIVITIES_REQUEST:
    nextState = {...state, payments: null, activitiesLoading: true};
    break;
  case ActionTypes.FETCH_ACTIVITIES_SUCCESS:
    nextState = {...state, activities: payload.items, activitiesLoading: false};
    break;
  case ActionTypes.FETCH_ACTIVITIES_FAILURE:
    nextState = {...state, activitiesLoading: false};
    break;
  case ActionTypes.FETCH_SUPPLIER_PAYMENTS_REQUEST:
    nextState = {...state, payments: null, paymentsLoading: true};
    break;
  case ActionTypes.FETCH_SUPPLIER_PAYMENTS_SUCCESS:
    nextState = {...state, payments: payload.items, paymentsSummary: payload.summary, paymentsLoading: false};
    break;
  case ActionTypes.FETCH_SUPPLIER_PAYMENTS_FAILURE:
    nextState = {...state, payments: null, paymentsLoading: false};
    break;
  case ActionTypes.PAYMENT_FORM_SUBMIT_SUCCESS:
    nextState = {...state, payments: payload.items, paymentsSummary: payload.summary, submitting: false, justSubmitted: true};
    break;
  case ActionTypes.PAYMENT_FORM_SUBMIT_REQUEST:
    nextState = {...state, submitting: true, justSubmitted: false};
    break;
  case ActionTypes.ACTIVITY_FORM_SUBMIT_REQUEST:
  case ActionTypes.ACTIVITY_BULK_FORM_SUBMIT_REQUEST:
  case ActionTypes.ACTIVITY_BULK_ADD_FORM_SUBMIT_REQUEST:
    nextState = {...state, submitting: true, justSubmitted: false};
    break;
  case ActionTypes.ACTIVITY_FORM_SUBMIT_SUCCESS:
  case ActionTypes.ACTIVITY_BULK_ADD_FORM_SUBMIT_SUCCESS:
    nextState = {...state, submitting: false, justSubmitted: true, activities: mergeItemsBy(state.activities, payload)};
    break;
  case ActionTypes.ACTIVITY_BULK_FORM_SUBMIT_SUCCESS:
    nextState = {...state, submitting: false, justSubmitted: true, activities: payload.items};
    break;
  case ActionTypes.ACTIVITY_FORM_SUBMIT_FAILURE:
  case ActionTypes.PAYMENT_FORM_SUBMIT_FAILURE:
  case ActionTypes.ACTIVITY_BULK_FORM_SUBMIT_FAILURE:
    case ActionTypes.ACTIVITY_BULK_ADD_FORM_SUBMIT_FAILURE:
    nextState = {...state, submitting: false};
    break;
  case PagesActionTypes.ACTIVITIES_ARCHIVED_STATE_TOGGLED:
      nextState = {...state, activitiesFilter: {showArchived: payload}};
      break;
  case ActionTypes.ACTIVITY_CHANGE_STATE_SUCCESS: {
    nextState = {...state, activities: state.activities.filter(p => p.id !== payload.id)};
    break;
  }
  default:
    nextState = state;
  }
  return nextState;
};

export default projectsReducer;
