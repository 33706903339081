import React, { useEffect, useState } from "react";
import {
  Button, Tooltip, Typography, Table, TableBody, TableCell, 
  TableContainer, TableRow, TextField, Paper, Toolbar, CircularProgress
} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import {currencyFormatter} from 'utils/i18nUtils';

// components
import { priceFormatter, relativeDateFormatter } from "utils/formatters";
import useStyles from "./styles";



function ConversionSection({amount, currenciesLastUpdated, rate, onRefreshRates, updatingCurrencies}) {
    const [relativeDateValue, setRelativeDateValue] = useState('');
    const [rateValue, setRate] = useState(rate);
    useEffect(() => {
        setRate(rate);
    }, [rate]);

    function onTooltipOpen() {
        setRelativeDateValue(relativeDateFormatter(currenciesLastUpdated))
    }
    
    function handleRefreshClick() {onRefreshRates()}
    const classes = useStyles();
    const parsedRate = parseFloat(rateValue);
    const isValidRate = !Number.isNaN(parsedRate)
    const result = isValidRate ? (amount * parseFloat(rateValue) || 0).toFixed(2) : 'שער לא תקין'
    const lastUpdate = `עודכן לאחרונה ${relativeDateValue}`;
    return (
      <>
      <TableRow>
        <TableCell colSpan={2}>
          <Toolbar className={classNames(classes.conversionToolbar, classes.toolbar)}>
            <Typography variant="h6" component="div">המרת מטבע</Typography>
            <Tooltip arrow title={lastUpdate} onOpen={onTooltipOpen}>
            <div className={classes.btnLoaderWrapper}>
              <Button disabled={updatingCurrencies} variant="outlined" color="primary" onClick={handleRefreshClick}>
                  עדכן שער
                </Button>
                {updatingCurrencies && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            </Tooltip>
          </Toolbar>
        </TableCell>
      </TableRow>
      <TableRow hover>
        <TableCell variant="head">שער חליפין</TableCell>
        <TableCell>
          <TextField
            value={rateValue}
            size="small"
            placeholder="שער"
            type="number"
            onChange={e => setRate(e.target.value)}
          />
        </TableCell>
      </TableRow>
      <TableRow hover>
        <TableCell variant="head">יתרת תשלום + מע"מ + שער חליפין</TableCell>
        <TableCell>{priceFormatter(result)} {currencyFormatter('ILS')}</TableCell>
      </TableRow>
      </>
    )
  }

export default function ProjectSupplierSummary(props) {
    const {activitiesSummary, showRateConversion, paymentsSummary, settings} = props;
    const classes = useStyles();
    const totalPaied = _.get(paymentsSummary, 'total');
    const totalNonVat = activitiesSummary.debtAmount - totalPaied;
    const vatPercent = settings.vat;
    const vatAmount = totalNonVat * vatPercent / 100;
    const totalVatted = totalNonVat + vatAmount;
    const currency = _.get(props, 'project.supplier.currency');
    const formattedCurrency = currencyFormatter(currency);
    return (
      <TableContainer component={Paper} elevation={4}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" component="div">סיכום כללי</Typography>
        </Toolbar>
        <Table size="small">
          <TableBody>
            <TableRow hover className={classes.tableRow}>
              <TableCell className={classes.tableCell} variant="head">מצטבר חוזי</TableCell>
              <TableCell className={classes.tableCell}>{priceFormatter(activitiesSummary.contractDebtAmount)} {formattedCurrency}</TableCell>
            </TableRow>
            <TableRow hover className={classes.tableRow}>
              <TableCell className={classes.tableCell} variant="head">מצטבר תוספות</TableCell>
              <TableCell className={classes.tableCell}>{priceFormatter(activitiesSummary.additionsDebtAmount)} {formattedCurrency}</TableCell>
            </TableRow>
            <TableRow hover className={classes.tableRow}>
              <TableCell className={classes.tableCell} variant="head">סה"כ מצטבר</TableCell>
              <TableCell className={classes.tableCell}>{priceFormatter(activitiesSummary.debtAmount)} {formattedCurrency}</TableCell>
            </TableRow>
            <TableRow hover className={classes.tableRow}>
              <TableCell className={classes.tableCell} variant="head">סה"כ שולם</TableCell>
              <TableCell className={classes.tableCell}>{priceFormatter(totalPaied, false)} {formattedCurrency}</TableCell>
            </TableRow>
            <TableRow hover className={classes.tableRow}>
              <TableCell className={classes.tableCell} variant="head">יתרת תשלום</TableCell>
              <TableCell className={classes.tableCell}>{priceFormatter(totalNonVat)} {formattedCurrency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} className={classes.toolbarCell}>
                <Toolbar className={classes.toolbar}>
                  <Typography variant="h6" component="div">סיכום מע"מ</Typography>
                </Toolbar>
              </TableCell>
            </TableRow>
            <TableRow hover className={classes.tableRow}>
              <TableCell className={classes.tableCell} variant="head">ערך מע"מ</TableCell>
              <TableCell className={classes.tableCell}>{priceFormatter(vatPercent, false)}%</TableCell>
            </TableRow>
            <TableRow hover className={classes.tableRow}>
              <TableCell className={classes.tableCell} variant="head">תוספת מע"מ</TableCell>
              <TableCell className={classes.tableCell}>{priceFormatter(vatAmount)} {formattedCurrency}</TableCell>
            </TableRow>
            <TableRow hover className={classes.tableRow}>
              <TableCell className={classes.tableCell} variant="head">יתרת תשלום כולל מע"מ</TableCell>
              <TableCell className={classes.tableCell}>{priceFormatter(totalVatted)} {formattedCurrency}</TableCell>
            </TableRow>
            {showRateConversion && <ConversionSection {...props} amount={totalVatted} />}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }