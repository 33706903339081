import {connect} from 'utils/reduxUtils';
import Component from './ProjectsPage';
import * as ProjectsSelectors from 'modules/projects/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onMount: Actions.PROJECTS_PAGE_MOUNTED,
    onShowHiddenChange: Actions.PROJECTS_PAGE_SHOW_ARCHIVED_CHANGE,
    onProjectChangeState: Actions.PROJECTS_PAGE_TOGGLE_PROJECT_STATE,
};

const mapStateToProps = state => {
    return {
        loading: ProjectsSelectors.isLoading(state),
        justSubmitted: ProjectsSelectors.justSubmitted(state),
        projects: ProjectsSelectors.projects(state),
        filter: ProjectsSelectors.filter(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
