import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'LOAD_USERS_REQUEST',
    'LOAD_USERS_SUCCESS',
    'LOAD_USERS_FAILURE',

    'USER_FORM_SUBMIT_REQUEST',
    'USER_FORM_SUBMIT_SUCCESS',
    'USER_FORM_SUBMIT_FAILURE',

    'DELETE_USER_SUBMIT_REQUEST',
    'DELETE_USER_SUBMIT_SUCCESS',
    'DELETE_USER_SUBMIT_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
