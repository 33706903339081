import { createSelector } from 'reselect';

export const sliceSelector = (state) => state.suppliers;
export const suppliers = createSelector(sliceSelector, slice => slice.suppliers || []);
export const suppliersNames = createSelector(sliceSelector, slice => slice.suppliersNames || []);
export const supplierProjects = createSelector(sliceSelector, slice => slice.supplierProjects || []);
export const isLoading = createSelector(sliceSelector, slice => !!slice.loading);
export const isSubmitting = createSelector(sliceSelector, slice => !!slice.submitting);
export const justSubmitted = createSelector(sliceSelector, slice => !!slice.justSubmitted);

export const filter = createSelector(sliceSelector, slice => slice.filter);
export const submitError = createSelector(sliceSelector, slice => slice.submitError);