import {connect} from 'utils/reduxUtils';
import Component from './ProjectSupplier';
import * as ProjectsSelectors from 'modules/projects/selectors';
import * as SettingsSelectors from 'modules/settings/selectors';
import * as PSSelectors from 'modules/projectSuppliers/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onMount: Actions.PROJECT_SUP_PAGE_MOUNTED,
    onShowHiddenChange: Actions.ACTIVITIES_ARCHIVED_STATE_TOGGLED,
    onDownloadReport: Actions.PROJ_SUP_PAGE_DOWNLOAD_REPORT,
    onActivityChangeState: Actions.PROJ_SUP_PAGE_TOGGLE_ACTIVITY_STATE,
};

const mapStateToProps = state => {
    return {
        loading: PSSelectors.isLoading(state),
        filter: PSSelectors.activitiesFilter(state),
        project: ProjectsSelectors.selectedProject(state),
        activities: PSSelectors.activities(state),
        activitiesSummary: PSSelectors.activitiesSummary(state),
        justSubmitted: PSSelectors.justSubmitted(state),
        currenciesLastUpdated: SettingsSelectors.currenciesLastUpdated(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
