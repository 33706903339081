import { mergeBy } from 'utils/reduxUtils';
import {ActionTypes} from './actions';
import _ from 'lodash';

const initialValue = {
}

const formatError = (payload) => {
    const errorCode = _.get(payload, 'response.data.key');
    switch (errorCode) {
      case 'userExists':
        return 'שם המשתמש כבר קיים במערכת';
      default:
        return 'אופס. נראה שהבקשה נכשלה';
    }
  }

const usersReducer = (state = initialValue, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.LOAD_USERS_REQUEST:
        nextState = {...state, users: [], loading: true};
        break;
    case ActionTypes.LOAD_USERS_SUCCESS:
        nextState = {...state, users: payload.items, loading: false};
        break;
    case ActionTypes.LOAD_USERS_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.USER_FORM_SUBMIT_REQUEST:
    case ActionTypes.DELETE_USER_SUBMIT_REQUEST:
        nextState = {...state, submitting: true, justSubmitted: false, submitError: null};
        break;
    case ActionTypes.USER_FORM_SUBMIT_SUCCESS:
        nextState = {...state, submitting: false, justSubmitted: true, users: mergeBy(state.users, payload)};
        break;
    case ActionTypes.DELETE_USER_SUBMIT_SUCCESS:
        nextState = {...state, submitting: false, justSubmitted: true, users: state.users.filter(p => p.id !== payload.id)};
        break;
    case ActionTypes.USER_FORM_SUBMIT_FAILURE:
    case ActionTypes.DELETE_USER_SUBMIT_FAILURE:
        nextState = {...state, submitting: false, submitError: formatError(payload)};
        break;
    default:
        nextState = state;
    }
    return nextState;
};

export default usersReducer;
