import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const projectsPageActions = [
    'PROJECTS_PAGE_MOUNTED',
    'PROJECTS_PAGE_SHOW_ARCHIVED_CHANGE',
    'PROJECTS_PAGE_TOGGLE_PROJECT_STATE',

    'SUPPLIERS_PAGE_MOUNTED',
    'SUPPLIERS_PAGE_SHOW_ARCHIVED_CHANGE',
    'SUPPLIERS_PAGE_TOGGLE_SUPPLIER_STATE',
    
    'LOGIN_FORM_SUBMITTED',
    'HEADER_LOGOUT_REQUESTED',

    'S_PROJECT_PAGE_MOUNTED',
    'S_PROJECT_PAGE_ARCHIVED_TOGGLED',
    'S_PROJECT_PAGE_TOGGLE_SUPPLIER_STATE',
    'S_PROJECT_PAGE_DOWNLOAD_REPORT',
    
    'PROJECT_SUP_PAGE_MOUNTED',
    'PROJ_SUP_PAGE_DOWNLOAD_REPORT',
    'PROJ_SUP_PAGE_TOGGLE_ACTIVITY_STATE',
    
    'PROJECT_FORM_SUBMITTED',
    'PROJECT_SUPPLIER_FORM_SUBMITTED',

    'SUPPLIER_FORM_SUBMITTED',
    
    'PS_REFRESH_RATES_CLICKED',
    
    'PAYMENTS_SECTION_MOUNTED',
    'PAYMENT_FORM_SUBMITTED',
    
    'ACTIVITY_FORM_SUBMITTED',
    'ACTIVITY_BULK_EDIT_FORM_SUBMITTED',
    'ACTIVITY_BULK_ADD_FORM_SUBMITTED',
    'ACTIVITIES_ARCHIVED_STATE_TOGGLED',

    'SUPPLIER_PROJECTS_MODAL_CHANGE',

    'SETTINGS_PAGE_SUBMIT',

    'USERS_PAGE_MOUNTED',
    'USER_FORM_SUBMITTED',
    'USERS_PAGE_DELETE_USER',
];

const allActions = [
    ...projectsPageActions
]
export const ActionTypes = createActionTypes(allActions);
export const Actions = createActionCreators(allActions);
