import React from "react";
import MUIDataTable from "mui-datatables";
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import {Button, Grid, FormControlLabel, Switch, Tooltip, IconButton} from '@material-ui/core';
import {
  Edit as EditIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
} from '@material-ui/icons';

// components
import PageTitle from "../../components/PageTitle";
import {muiDataTableLabels} from 'utils/i18nUtils'
import Loader from "components/Loader/Loader";
import CreateEditProject from "components/CreateEditProject";
import { withStyles } from "@material-ui/styles";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { centralizeColumn } from "utils/materialUtils";


function ProjectProgress(value) {
  return (
    <LinearProgressWithLabel variant="determinate" value={value} />
  );
}

class ProjectsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDialogOpen: false,
      confirmation: {open: false},
      columns: [
        {name: 'name', label: 'שם הפרויקט'},
        {name: 'phone', label: 'טלפון'},
        {name: 'email', label: 'אימייל'},
        {name: 'location', label: 'מיקום'},
        {name: 'details', label: 'פרטי הפרויקט'},
        {
          name: 'completed',
          label: 'אחוזי התקדמות',
          options: {
            filter: false,
            sort: true,
            customBodyRender: ProjectProgress,
          }
        },
        {
          name: 'actions',
          label: 'פעולות',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: this.renderActionsColumn,
            ...centralizeColumn,
          },
        },
      ]
    }
  }

  componentDidUpdate = (oldProps) => {
    if (!oldProps.justSubmitted && this.props.justSubmitted) {
      this.setState({justSubmitted: true})
    }
  }
  componentDidMount = () => {
    this.props.onMount();
  }
  handleShowArchivedChange = (e) => {
    const isHidden = e.target.checked;
    this.props.onShowHiddenChange(isHidden);
  }

  handleCellClick = (_, {colIndex, dataIndex}) => {
    if (colIndex === this.state.columns.length -1) {
      return;
    }
    const {projects, history, match} = this.props;
    const project = projects[dataIndex];
    history.push(`${match.url}/${project.id}`);
  }

  handleEditIcon = (project) => {
    this.setState({selectedProject: project, projectDialogOpen: true, justSubmitted: false});
  }
  
  handleProjectDialogClose = () => {
    this.setState({projectDialogOpen: false, justSubmitted: false});
  }

  closeConfirmationDialog = () => {
    this.setState({confirmation: {open: false}});
  }

  handleProjectChangeState = (project) => {
    this.closeConfirmationDialog();
    this.props.onProjectChangeState(project)
  }

  confirmChangeProjectState = (project) => {
    const activateTexts = {
      title: `הוצאת פרויקט מארכיון - ${project.name}`,
      subtitle: 'האם אתה בטוח כי ברצונך להוציא פרויקט זה מארכיון?',
      confirmText: 'הוצאה מארכיון',
    }
    const archiveTexts = {
      title: `העברת פרויקט לארכיון - ${project.name}`,
      subtitle: 'האם אתה בטוח כי ברצונך להעביר פרויקט זה לארכיון?',
      confirmText: 'העברה לארכיון',
    }
    const confirmation = {
      open: true,
      ...(project.active ? archiveTexts : activateTexts),
      onConfirm: () => this.handleProjectChangeState(project),
      onCancel: this.closeConfirmationDialog
    }
    this.setState({confirmation})
  }

  renderToolbar = () => {
    const {showArchived} = this.props.filter || {};

    return (
      <>
        <FormControlLabel
          control={<Switch checked={showArchived} onChange={this.handleShowArchivedChange} name="checkedA" />}
          label="הצג פרויקטים מוסתרים"
        />
        <Button variant="contained" color="primary" onClick={() => this.handleEditIcon(undefined)}>פרויקט חדש</Button>
      </>
    );
  }

  renderActionsColumn = (dataIndex) => {
    const {projects, classes} = this.props;
    const project = projects[dataIndex]
    if (!project) {return;}
    
    const Icon = project.active ? ArchiveIcon : UnarchiveIcon;
    const text = project.active ? 'העבר לארכיון' : 'הפוך לפעיל';
    return (
      <>
        <Tooltip arrow title={text}>
          <IconButton aria-label={text} className={classes.actionIcon} onClick={() => this.confirmChangeProjectState(project)} >
            <Icon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="עריכת פרויקט">
          <IconButton aria-label="עריכת פרויקט" className={classes.actionIcon} onClick={() => this.handleEditIcon(project)}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderConfirmDialog = () => {
    return (
      <ConfirmDialog
          {...this.state.confirmation}
      />
    );
  }
  render() {
    const {projects, loading} = this.props;
    const {projectDialogOpen, selectedProject, columns, justSubmitted} = this.state;
    return (
      <>
        <PageTitle title="הפרויקטים שלי" />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Loader loading={loading}>
              <MUIDataTable
                title="רשימת הפרויקטים"
                data={projects}
                columns={columns}
                options={{
                  filterType: "textField",
                  sortOrder: {name: 'name', direction: 'asc'},
                  onCellClick: this.handleCellClick,
                  textLabels: muiDataTableLabels,
                  selectableRows: 'none',
                  pagination: false,
                  filter: false,
                  search: false,
                  searchOpen: true,
                  print: false,
                  download: false,
                  viewColumns: false,
                  selectableRowsOnClick: true,
                  responsive: 'standard',
                  customToolbar: this.renderToolbar,
                }}
              />
            </Loader>
          </Grid>
        </Grid>
        <CreateEditProject
          justSubmitted={justSubmitted}
          open={projectDialogOpen}
          project={selectedProject}
          onClose={this.handleProjectDialogClose}
        />
        {this.renderConfirmDialog()}
      </>
    );
  }
}

const styles = theme => ({
  actionIcon: {
    padding: theme.spacing(1),
  },
  summaryTable: {
    marginTop: theme.spacing(4),
  },
})
export default withStyles(styles, { withTheme: true })(ProjectsPage);