import React from "react";
import MUIDataTable from "mui-datatables";
import {Button, Grid, Tooltip, IconButton} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import {muiDataTableLabels} from 'utils/i18nUtils'
import Loader from "components/Loader/Loader";
import CreateEditUser from "components/CreateEditUser";
import { withStyles } from "@material-ui/styles";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { centralizeColumn } from "utils/materialUtils";


class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDialogOpen: false,
      showDeleteConfirmationModal: false,
      columns: [
        {name: 'name', label: 'שם'},
        {name: 'username', label: 'אימייל'},
        {
          name: 'actions',
          label: 'פעולות',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: this.renderActionsColumn,
            ...centralizeColumn,
          },
        },
      ]
    }
  }

  componentDidUpdate = (oldProps) => {
    if (!oldProps.justSubmitted && this.props.justSubmitted) {
      this.setState({justSubmitted: true, showDeleteConfirmationModal: false})
    }
  }
  componentDidMount = () => {
    this.props.onMount();
  }

  handleEditIcon = (user) => {
    this.setState({selectedUser: user, userDialogOpen: true, justSubmitted: false});
  }
  
  handleUserDialogClose = () => {
    this.setState({userDialogOpen: false, justSubmitted: false});
  }

  closeConfirmationDialog = () => {
    this.setState({confirmation: {open: false}});
  }

  handleUserDeleted = (project) => {
    this.closeConfirmationDialog();
    this.props.onUserDeleted(project)
  }

  confirmChangeProjectState = (project) => {
    const text = {
      title: `מחיקת משתמש - ${project.name}`,
      subtitle: 'האם אתה בטוח כי ברצונך למחוק משתמש זה?',
      confirmText: 'מחיקה',
    }
    const confirmation = {
      open: true,
      ...text,
      onConfirm: () => this.handleUserDeleted(project),
      onCancel: this.closeConfirmationDialog
    }
    this.setState({confirmation})
  }

  renderToolbar = () => {
    return (
      <>
        <Button variant="contained" color="primary" onClick={() => this.handleEditIcon(undefined)}>משתמש חדש</Button>
      </>
    );
  }

  renderActionsColumn = (dataIndex) => {
    const {users, classes} = this.props;
    const user = users[dataIndex]
    if (!user) {return;}
    
    return (
      <>
        <Tooltip arrow title="מחיקת משתמש">
          <IconButton
            aria-label="מחיקת משתמש"
            className={classes.actionIcon}
            onClick={() => this.setState({showDeleteConfirmationModal: true, selectedUser: user})}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="עריכת משתמש">
          <IconButton aria-label="עריכת משתמש" className={classes.actionIcon} onClick={() => this.handleEditIcon(user)}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderConfirmDialog = () => {
    const {showDeleteConfirmationModal, selectedUser} = this.state;
    const name = selectedUser ? selectedUser.name : '';
    return (
      <ConfirmDialog
        variant="error"
        open={showDeleteConfirmationModal}
        title={`מחיקת משתמש - ${name}`}
        subtitle={`האם אתה בטוח שברצונך למחוק את המשתמש "${name}"?`}
        onConfirm={() => this.props.onDeleteUser(selectedUser)}
        confirmText="מחיקה"
        loading={this.props.submitting}
        onCancel={() => this.setState({showDeleteConfirmationModal: false, selectedUser: null})}
      />
    );
  }
  render() {
    const {users, loading} = this.props;
    const {userDialogOpen, selectedUser, columns, justSubmitted} = this.state;
    return (
      <>
        <PageTitle title="המשתמשים שלי" />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Loader loading={loading}>
              <MUIDataTable
                title="רשימת המשתמשים"
                data={users}
                columns={columns}
                options={{
                  filterType: "textField",
                  textLabels: muiDataTableLabels,
                  selectableRows: 'none',
                  pagination: false,
                  filter: false,
                  search: false,
                  searchOpen: true,
                  print: false,
                  download: false,
                  viewColumns: false,
                  selectableRowsOnClick: true,
                  responsive: 'standard',
                  customToolbar: this.renderToolbar,
                }}
              />
            </Loader>
          </Grid>
        </Grid>
        <CreateEditUser
          justSubmitted={justSubmitted}
          open={userDialogOpen}
          user={selectedUser}
          onClose={this.handleUserDialogClose}
        />
        {this.renderConfirmDialog()}
      </>
    );
  }
}

const styles = theme => ({
  actionIcon: {
    padding: theme.spacing(1),
  },
  summaryTable: {
    marginTop: theme.spacing(4),
  },
})
export default withStyles(styles, { withTheme: true })(UsersPage);
