import { createSelector } from 'reselect';
import * as NavSelectors from 'modules/navigation/selectors';
import _ from 'lodash';

export const sliceSelector = (state) => state.projectSuppliers;
export const payments = createSelector(sliceSelector, slice => slice.payments || []);
export const paymentsSummary = createSelector(sliceSelector, slice => slice.paymentsSummary);
export const isSubmitting = createSelector(sliceSelector, slice => slice.submitting);
export const justSubmitted = createSelector(sliceSelector, slice => slice.justSubmitted);
export const activitiesFilter = createSelector(sliceSelector, slice => slice.activitiesFilter);
export const activities = createSelector(sliceSelector, slice => slice.activities || []);
export const isLoading = createSelector(sliceSelector, slice => !!(slice.activitiesLoading || slice.paymentsLoading));

export const activitiesSummary = createSelector(activities, ac => {
    const initial = {
        contractDebtAmount: 0, additionsDebtAmount: 0, rawtotalAmount: 0, totalAmount: 0, debtAmount: 0,
    }
    return _.transform(ac, (aggr, activity) => {
        const amountKey = activity.type === 'contract' ? 'contractDebtAmount' : 'additionsDebtAmount';
        aggr[amountKey] += activity.debtAmount;
        aggr.debtAmount += activity.debtAmount;
    }, initial);
});


export const matchingSupplierId = createSelector(
    NavSelectors.pathnameSelector,
    (pathname) => {
        const matchedRegex = (pathname || '').match(/\/projects\/[^/?]*\/suppliers\/([^/?]*)/) || [];
        const id = matchedRegex[1];
        return parseInt(id);
    }
);